.container {
    width: 68vw;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1px;
    position: relative;
}

@media screen and (max-width: 570px) {
    .container {
        width: 100vw;
    }
}